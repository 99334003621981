<template>
  <QRDemo @scan-result="handleScanResult"/>
  <p v-if="scanResult">扫描结果: {{ scanResult }}</p>
</template>

<script setup>
import {ref} from 'vue';
import QRDemo from "@/components/QRDemo.vue";

const scanResult = ref('');

const handleScanResult = (result) => {
  scanResult.value = result;
};
</script>

<style>
</style>
