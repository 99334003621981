<template>
  <div>
    <div id="qr-reader" style="width: 100%; height: 100%;"></div>
    <button @click="startScanning">开始扫描</button>
  </div>
</template>

<script setup>
import {defineEmits, ref} from 'vue';
import {Html5Qrcode} from 'html5-qrcode';

const emit = defineEmits(['scan-result']);

const result = ref('');
let html5Qrcode;

const startScanning = () => {
  if (html5Qrcode) {
    html5Qrcode.stop().catch(err => {
      console.error(err);
    });
  }

  html5Qrcode = new Html5Qrcode("qr-reader");

  html5Qrcode.start(
      {facingMode: "environment"}, // 使用后置摄像头
      {
        fps: 10, // 解析帧率
        qrbox: 250, // 选择的扫描区域
      },
      (decodedText) => {
        // 扫描成功
        result.value = decodedText;

        // 触发自定义事件，将结果传递给父组件
        emit('scan-result', decodedText);

        // 关闭摄像头
        html5Qrcode.stop().catch(err => {
          console.error(err);
        });
      },
      (error) => {
        // 扫描失败
        console.error(error);
      }
  ).catch(err => {
    console.error(err);
  });
};
</script>

<style scoped>
/* 自定义样式 */
#qr-reader {
  width: 100%;
  height: 500px; /* 设置二维码扫描区域的高度 */
}
</style>
